import React, { useState } from 'react';

import classes from './Clientes.module.scss';
import logoAmigao from '../../../assets/images/clientes/Amigao.jpg';
import logoAraujo from '../../../assets/images/clientes/Araujo.jpg';
import logoBomDia from '../../../assets/images/clientes/BomDia.jpg';
import logoGolff from '../../../assets/images/clientes/Golff.png';
import logoJau from '../../../assets/images/clientes/Jau.png';
import logoLegal from '../../../assets/images/clientes/Legal.png';
import logoLopes from '../../../assets/images/clientes/Lopes.png';
import logoPanvel from '../../../assets/images/clientes/Panvel.png';
import logoStock from '../../../assets/images/clientes/Stock.png';
import logoSupermaxi from '../../../assets/images/clientes/Supermaxi.png';
import logoVeneza from '../../../assets/images/clientes/Veneza.png';
import logoVerona from '../../../assets/images/clientes/Verona.png';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FiChevronRight } from 'react-icons/fi';
import { FiChevronLeft } from 'react-icons/fi';

const Clientes = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const isMobilePage = width <= 768;

    function PrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                id={classes.prev_arrow}
                style={{
                    ...style,
                    display: 'block',
                    color: 'black',
                    background: 'none',
                    zIndex: 1,
                    fontSize: '90px',
                    alignItems: 'center',
                    width: '60px',
                    height: '100%',
                }}
                onClick={onClick}
            >
                <FiChevronLeft style={{ width: '100%' }} />
            </div>
        );
    }

    function NextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                id={classes.next_arrow}
                className={className}
                style={{
                    ...style,
                    display: 'block',
                    alignItems: 'center',
                    color: 'black',
                    background: 'none',
                    zIndex: 1,
                    fontSize: '90px',
                    width: '60px',
                    height: '100%',
                }}
                onClick={onClick}
            >
                <FiChevronRight style={{ width: '100%' }} />
            </div>
        );
    }

    var settingDesktop = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 2,
        className: classes.clientes__items__desktop,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,

        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 6,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    var settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 2,
        className: classes.clientes__items,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 6,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    if (!isMobilePage) {
        return (
            <section id="clientes" className={classes.clientes__desktop}>
                <div className={classes.titulo_thispage__desktop}>
                    <p>
                        Redes que confiam na <span className={classes.destacado}>Minus</span>
                    </p>
                </div>

                <Slider {...settingDesktop}>
                    <div className={classes.logo_cliente__desktop}>
                        <a href="https://www.grupoamigao.com/" target="_blank" rel="noopener noreferrer">
                            <img src={logoAmigao} alt="logo do cliente Amigao" />
                        </a>
                    </div>
                    <div className={classes.logo_cliente__desktop}>
                        <a href="https://www.araujo.com.br/" target="_blank" rel="noopener noreferrer">
                            <img src={logoAraujo} alt="logo do cliente Araujo" />
                        </a>
                    </div>
                    <div className={classes.logo_cliente__desktop}>
                        <a href="https://www.supermercadosbomdia.com.br/" target="_blank" rel="noopener noreferrer">
                            <img src={logoBomDia} alt="logo do cliente Araujo" />
                        </a>
                    </div>
                    <div className={classes.logo_cliente__desktop}>
                        <a href="https://www.jauserve.com.br" target="_blank" rel="noopener noreferrer">
                            <img src={logoJau} alt="logo do cliente Jau" />
                        </a>
                    </div>
                    <div className={classes.logo_cliente__desktop}>
                        <a href="https://redesupermaxi.com.br/" target="_blank" rel="noopener noreferrer">
                            <img src={logoSupermaxi} alt="logo do cliente Supermaxi" />
                        </a>
                    </div>
                    <div className={classes.logo_cliente__desktop}>
                        <a href="https://www.grupopanvel.com.br/pt" target="_blank" rel="noopener noreferrer">
                            <img src={logoPanvel} alt="logo do cliente Panvel" />
                        </a>
                    </div>
                    <div className={classes.logo_cliente__desktop}>
                        <a href="https://veronasupermercados.com.br/site" target="_blank" rel="noopener noreferrer">
                            <img src={logoVerona} alt="logo do cliente Verona" />
                        </a>
                    </div>
                    <div className={classes.logo_cliente__desktop}>
                        <a href="https://supergolff.com.br/" target="_blank" rel="noopener noreferrer">
                            <img src={logoGolff} alt="logo do cliente Golff" />
                        </a>
                    </div>
                    <div className={classes.logo_cliente__desktop}>
                        <a href="https://legalsupermercados.lojaqui.com.br/" target="_blank" rel="noopener noreferrer">
                            <img src={logoLegal} alt="logo do cliente Legal" />
                        </a>
                    </div>
                    <div className={classes.logo_cliente__desktop}>
                        <a href="https://stockatacadista.com.br/" target="_blank" rel="noopener noreferrer">
                            <img src={logoStock} alt="logo do cliente Stock" />
                        </a>
                    </div>
                    <div className={classes.logo_cliente__desktop}>
                        <a
                            href="https://www.facebook.com/venezasupermercados/?locale=pt_BR"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={logoVeneza} alt="logo do cliente Veneza" />
                        </a>
                    </div>
                </Slider>
                <div className={classes.container}>
                    <button
                        className={classes.button__desktop}
                        onClick={() =>
                            window.open(
                                'https://api.whatsapp.com/send/?phone=5544999824060&text=Ol%C3%A1%2C+vim+pelo+site+e+gostaria+de+obter+mais+informa%C3%A7%C3%B5es+sobre+os+sistemas+da+Minus&type=phone_number&app_absent=0',
                                '_blank',
                                'noopener noreferrer'
                            )
                        }
                    >
                        FALE CONOSCO
                    </button>
                </div>
            </section>
        );
    } else {
        return (
            <section id="clientes" className={classes.clientes}>
                <div className={classes.titulo_thispage}>
                    <p>
                        Redes que confiam na <span className={classes.destacado}>Minus</span>
                    </p>
                </div>

                <Slider {...settings}>
                    <div className={classes.logo_cliente}>
                        <a href="https://www.grupoamigao.com/" target="_blank" rel="noopener noreferrer">
                            <img src={logoAmigao} alt="logo do cliente Amigao" />
                        </a>
                    </div>
                    <div className={classes.logo_cliente}>
                        <a href="https://www.jauserve.com.br" target="_blank" rel="noopener noreferrer">
                            <img src={logoJau} alt="logo do cliente Jau" />
                        </a>
                    </div>
                    <div className={classes.logo_cliente}>
                        <a href="https://www.supermercadolopes.com.br" target="_blank" rel="noopener noreferrer">
                            <img src={logoLopes} alt="logo do cliente Lopes" />
                        </a>
                    </div>
                    <div className={classes.logo_cliente}>
                        <a href="https://redesupermaxi.com.br/" target="_blank" rel="noopener noreferrer">
                            <img src={logoSupermaxi} alt="logo do cliente Supermaxi" />
                        </a>
                    </div>
                    <div className={classes.logo_cliente}>
                        <a href="https://www.grupopanvel.com.br/pt" target="_blank" rel="noopener noreferrer">
                            <img src={logoPanvel} alt="logo do cliente Panvel" />
                        </a>
                    </div>
                    <div className={classes.logo_cliente}>
                        <a href="https://veronasupermercados.com.br/site" target="_blank" rel="noopener noreferrer">
                            <img src={logoVerona} alt="logo do cliente Verona" />
                        </a>
                    </div>
                    <div className={classes.logo_bomdia}>
                        <a href="https://www.supermercadosbomdia.com.br/" target="_blank" rel="noopener noreferrer">
                            <img src={logoBomDia} alt="logo do cliente Araujo" />
                        </a>
                    </div>
                    <div className={classes.logo_cliente}>
                        <a href="https://www.araujo.com.br/" target="_blank" rel="noopener noreferrer">
                            <img src={logoAraujo} alt="logo do cliente Araujo" />
                        </a>
                    </div>
                    <div className={classes.logo_cliente}>
                        <a href="https://supergolff.com.br/" target="_blank" rel="noopener noreferrer">
                            <img src={logoGolff} alt="logo do cliente Golff" />
                        </a>
                    </div>
                    <div className={classes.logo_cliente}>
                        <a href="https://legalsupermercados.lojaqui.com.br/" target="_blank" rel="noopener noreferrer">
                            <img src={logoLegal} alt="logo do cliente Legal" />
                        </a>
                    </div>
                    <div className={classes.logo_cliente}>
                        <a href="https://stockatacadista.com.br/" target="_blank" rel="noopener noreferrer">
                            <img src={logoStock} alt="logo do cliente Stock" />
                        </a>
                    </div>
                    <div className={classes.logo_cliente}>
                        <a
                            href="https://www.facebook.com/venezasupermercados/?locale=pt_BR"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={logoVeneza} alt="logo do cliente Veneza" />
                        </a>
                    </div>
                </Slider>
                <div className={classes.container}>
                    <button
                        className={classes.button}
                        onClick={() =>
                            window.open(
                                'https://api.whatsapp.com/send/?phone=5544999824060&text=Ol%C3%A1%2C+vim+pelo+site+e+gostaria+de+obter+mais+informa%C3%A7%C3%B5es+sobre+os+sistemas+da+Minus&type=phone_number&app_absent=0',
                                '_blank',
                                'noopener noreferrer'
                            )
                        }
                    >
                        Fale com um consultor!
                    </button>
                </div>
            </section>
        );
    }
};

export default Clientes;
